<template>
  <q-dialog v-if="isMobile" position="bottom" v-model="dialogState" maximized>
    <q-card
      class="mobileCardDialogue"
      style="min-height:150px;max-height:90vh;"
    >
      <div class="mobileDialogTitle">
        <h5 class="text-h5">Select Category</h5>
        <q-icon class="close cursor-pointer" name="close" v-close-popup />
      </div>
      <div class="h-search-category-mobile">
        <a
          v-for="(cat, index) in allCategories"
          :key="cat.categoryID"
          href="javascript:void(0);"
          @click="setSearchCategory(index)"
          :class="{
            selected: searchCategory.categoryName == cat.categoryName,
          }"
          >{{ $options.filters.titleCase(cat.categoryName) }}</a
        >
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    dialogState: {
      get() {
        return this.getDialogState('selectCategory')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'selectCategory',
          val,
        })
      },
    },
    allCategories() {
      let categories = [
        { categoryID: '', categoryName: 'All' },
        ...this.categories,
      ]
      return categories
    },
    ...mapGetters('category', ['searchCategory', 'categories']),
  },
  methods: {
    setSearchCategory(catIndex) {
      this.$store.commit(
        'category/SET_SELECTED_SEARCH_CATEGORY',
        this.allCategories[catIndex]
      )

      this.changeDialogState({
        dialog: 'selectCategory',
        val: false,
      })
    },
  },
}
</script>
<style lang="scss">
.h-search-category-mobile {
  padding: 16px;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-left: -5px;
  a {
    margin: 5px;
    padding: 10px 15px;
    font-size: 14px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: $black;
    text-decoration: none;
    border-radius: 20px;
    &.selected {
      background: $primary;
      background: var(--q-color-primary) !important;
      border-color: $primary;
      border-color: var(--q-color-primary) !important;
      border-color: var(--q-color-primary) !important;
      color: $white;
    }
  }
}
</style>
